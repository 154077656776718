.social
    align-items: centre
    display: flex
    height: 6rem
    justify-content: space-around
    list-style: none
    margin: 1em auto
    padding: 0
    +breakpoint(laptop-plus)
      justify-content: space-evenly
    li
      flex-grow: 0
      height: 100%
      width: auto
    .background
      width: 100%
    .icon
      display: inline-block
      height: 100%
      width: auto
