.card
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2)
  display: flex
  flex-direction: column
  justify-content: flex-end
  margin: 0.5em 0
  overflow: hidden
  position: relative
  a
    color: white
    text-decoration: none
    &:focus
      text-decoration: underline
    &::after
      content: ""
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
  // Needs to be replaced by theme
  +all-headings
    background-color: hsla(0, 0%, 0%, 0.4)
    color: white
    font-size: 1.7em
    text-align: center
    margin: 0
  .card-links
    background-color: hsla(0, 0%, 0%, 0.4)
  img
    margin: auto
    transition: transform 0.2s ease-out
    width: 100%
    height: 100%
    object-fit: contain
  picture
    overflow: hidden
    flex-grow: 1
    object-fit: contain
  // Make the card react to show interactivity
  &:hover
    img
      transform: scale(1.15)
  &:focus-within
    img
      transform: scale(1.15)
    a:focus // Unset focus style where focus-within is supported
      text-decoration: none
  .overlay
    display: flex
    position: absolute
    top: 0
    width: 100%
    height: 100%
    flex-direction: column

.card-links
  display: flex
  flex-direction: row
  justify-content: flex-end
  list-style: none
  margin: auto 0 0 0 // Allows overriding justify-content
  // Ideally should tie into typography
  .sprite
    width: 2em
    height: 2em

// Pad between elements, but not at the ends
$link-padding: 0.25em
.card-link
  margin: 0 #{calc($link-padding / 2)}
  position: relative
  &:first-child
    margin-left: 0
  &:last-child
    margin-right: 0
