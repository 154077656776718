.pure-form input[type="password"],
.pure-form input[type="email"],
.pure-form input[type="url"],
.pure-form input[type="date"],
.pure-form input[type="month"],
.pure-form input[type="time"],
.pure-form input[type="datetime"],
.pure-form input[type="datetime-local"],
.pure-form input[type="week"],
.pure-form input[type="tel"],
.pure-form input[type="color"],
.pure-form input[type="number"],
.pure-form input[type="search"],
.pure-form input[type="text"],
.pure-form select,
.pure-form textarea
    box-shadow: unset

.pure-form button[type="submit"]
    margin: .7em 0 0
    width: 100%

.pure-form
    display: block
    margin-left: auto
    margin-right: auto

.pure-g
    justify-content: center
