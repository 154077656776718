#main-nav
  text-align: end

// Title exists for outline purposes
.nav-title
  +a11y-visually-hidden

.pure-menu-disabled,
.pure-menu-heading,
.pure-menu-link
  padding: 1em