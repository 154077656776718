$gutter: 0.5em

$portions: 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24
$breaks: ("": "", -sm: "", -md: tablet-plus, -lg: laptop-plus)
/*TAG*/
// Hacky stuff to augment pure-css grids with gutters
// NOTE: Not all purecss widths are accounted for
// TODO: Include all purecss widths
.pure-g.gutters
  justify-content: space-between
  @each $portion in $portions
    @each $break, $bp in $breaks
      $percentage: (calc(1 / $portion)) * 100 // Turn the portion into a percentage
      .pure-u#{$break}-1-#{$portion} // Generate matching purecss class name
        // Include a breakpoint if this is a purecss responsive class
        // NOTE: Does not match purecss breakpoints 1:1
        @if $bp != ""
          +breakpoint($bp)
            flex: 0 0 calc(#{$percentage}% - #{$gutter})
        @else
          flex: 0 0 calc(#{$percentage}% - #{$gutter})
