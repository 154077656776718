// Apply to items that should not be visible but should be available to SRs
=a11y-visually-hidden
  position: absolute !important
  border: 0
  padding: 0
  margin: -1px
  clip: rect(1px, 1px, 1px, 1px)
  overflow: hidden
  height: 1px
  width: 1px
  word-wrap: normal

// Breakpoint shorhand. Clarifies intent when used by being succinct
=breakpoint($min-width)
  @media only screen and (min-width: map-get($breakpoints, $min-width))
    @content

// Currently unused, consider removing
=two-way-shadow($amount, $blur-amount, $color)
  $blur: $blur-amount * 1px
  $pos: $amount * 1px
  $neg: $amount * -1px
  box-shadow: $pos $pos $blur $color, $neg $neg $blur $color

// For applying things like heading themes
=all-headings
  h1, h2, h3, h4, h5, h6
    @content
