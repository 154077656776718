// Tints
$black: hsl(0, 0%, 0%)
$dark-grey: hsl(0, 0%, 20%)
$white: hsl(0, 0%, 100%)

// Colors
$lime: hsl(90, 52%, 51%)
$hot-pink: hsl(334, 100%, 61%)

$paradise-pink-500: #ef476f
$dark-orange-500: #fe8d01
$golden-poppy-500: #fdc500
$india-green-500: #248905
$medium-sea-green: #30b870
$caribbean-green-500: #06d6a0
$charleston-green-500: #1e2d2f
$royal-purple-500: #7d5ba6

// Font faces
$open-sans: 'Open Sans'
$inter: 'Inter'

// Layout
$grid-gutter: 1em
$grid-bases: 2, 4, 5, 12, 24

// Breakpoints
$breakpoints: ()
$breakpoints: map-merge($breakpoints, (tablet-plus: 768px))
$breakpoints: map-merge($breakpoints, (laptop-plus: 1024px))
$breakpoints: map-merge($breakpoints, (big-desktop-plus: 1800px))

// Typography
$baseline: 1.5rem
$light-weight: 200
$standard-weight: 400
$strong-weight: 500

// Social links
$social: ()
$social: map-merge($social, (itch-io: #fa5c5c))
$social: map-merge($social, (linkedin: #0077B5))
$social: map-merge($social, (github: #24292E))
