// Core theme file
// Called from individual themes that provide parent selectors to nest these rules
// The default theme does not provide a selector

$shadow-amount: 3
$shadow-blur: 5

// These can't live in abstracts/placeholders because sass isn't lazy
// ...but they should
%accent-1-colored
  color: $white
  background-color: theme-color(accent-1)

%accent-2-colored
  color: $white
  background-color: theme-color(accent-2)

a
  color: theme-color(accent-2)

body
  background-color: theme-color(primary)
  color: theme-color(secondary)

em
  color: theme-color(accent-1)

.hamburger
  outline: none

.highlight
  color: theme-color(accent-2)
  font-size: 1.25em

#site-header
  @extend %accent-1-colored
  a
    color: theme-color(secondary)

#site-footer
  background-color: shade(theme-color(primary), 60%)

.site-icon
  background-color: theme-color(primary)
  box-shadow: 2px 2px 8px $black
    
  
.button-primary
  @extend %accent-1-colored

.button-secondary
  @extend %accent-2-colored

.card
  background: linear-gradient(120deg, theme-color(accent-1), 65%, theme-color(accent-2))

.card-links
  padding: 1em
  .sprite
    fill: theme-color(secondary)

.pure-menu-active>.pure-menu-link,
.pure-menu-link:focus,
.pure-menu-link:hover
  background-color: shade($paradise-pink-500, 40%)

.pure-menu-selected
  background-color: shade($paradise-pink-500, 20%)
  .pure-menu-link:hover
    background-color: shade($paradise-pink-500, 60%)

.social .icon
  fill: $white
  @each $name, $color in $social
    &.#{$name} .background
      fill: $color

.pure-form input:not([type]),
.pure-form input[type="password"],
.pure-form input[type="email"],
.pure-form input[type="url"],
.pure-form input[type="date"],
.pure-form input[type="month"],
.pure-form input[type="time"],
.pure-form input[type="datetime"],
.pure-form input[type="datetime-local"],
.pure-form input[type="week"],
.pure-form input[type="tel"],
.pure-form input[type="color"],
.pure-form input[type="number"],
.pure-form input[type="search"],
.pure-form input[type="text"],
.pure-form select,
.pure-form textarea
  background-color: shade(theme-color(primary), 40%)
  &:focus
    border-color: theme-color(accent-2)
