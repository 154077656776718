.is-flex-container
    display: flex

.is-flex-column
    flex-direction: column

.is-flex-row
    flex-direction: row

.flex-children-main-center
    justify-content: center

.flex-children-cross-center
    align-items: center

.flex-aligned-center
    align-self: center

.flex-justified-between
    justify-content: space-between

.flex-justified-center
    justify-content: center

.flex-wraps
    flex-wrap: wrap

.flex-no-shrink
    flex-shrink: 0

@for $i from -1 through 5
    .flex-group-#{$i}
        order: #{$i}