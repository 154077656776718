// Center the element using absolute positioning and transforms
+breakpoint(tablet-plus)
  %absolute-center-horizontal
    position: absolute
    left: 50%
    transform: translateX(-50%)

%absolute-center-vertical
  position: absolute
  top: 50%
  transform: translateY(-50%)
