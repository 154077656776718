// Ensure body is full height - makes footer go to the bottom
body
  min-height: 100vh

// Images responsive by default
img
  display: block
  max-width: 100%
  height: auto
  margin-left: auto
  margin-right: auto

// Give SVGs a default width/height for browsers that can't
svg
  height: 100%
  width: 100%

// Styles to make things dissapear at each defined breakpoint
// Useful e.g. for mobile menu button, provides atomic classes:
// e.g. class="disappears-on-tablet-plus"
@each $breakpoint, $size in $breakpoints
  .disappears-on-#{$breakpoint}
    +breakpoint($breakpoint)
      display: none
      visibility: hidden
