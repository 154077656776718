// Import fonts
@import url('https://rsms.me/inter/inter.css')

$font-stack: $inter, sans-serif

body
  font-size: 1rem //0.91em // 14.56px
  line-height: 1.5

// Fix some pure shennanigans
body, .pure-g [class*=pure-u]
  font-family: $font-stack
  font-weight: $standard-weight
  text-rendering: auto

strong
  font-weight: $strong-weight

.site-header
  font-size: 1.41em
  font-weight: $strong-weight
