@each $grid-base in $grid-bases
    @for $i from 1 through $grid-base
        .is-#{$i}-of-#{$grid-base}
            width: calc(#{$i} / #{$grid-base} * 100%)
    @each $name, $size in $breakpoints
        @for $i from 1 through $grid-base
            .is-#{$i}-of-#{$grid-base}-on-#{$name}
                +breakpoint($name)
                    width: calc( #{$i} / #{$grid-base} * 100%)

.no-margins
    margin: 0