#site-header
  // Margin pads the content below from the icon
  margin: 1em
  margin-bottom: 2.5rem
  //padding-right:  1em
  text-align: center
  top: 1em
  z-index: 1 // stay on top
  a
    text-decoration: none
  // This really should be named .site-heading
  .site-header
    //align-self: center
    //text-align: left
    // padding-left: 1em
  & > .pure-g
    justify-content: space-between
    +breakpoint(laptop-plus)
      justify-content: center

// Centered site icon
.site-icon
  border-radius: 50%
  +breakpoint(tablet-plus)
    @extend %absolute-center-horizontal

    z-index: 1 // Keep on top
    max-width: 4rem // Controls icon size on larger devices
    top: 50% // Offset from top to center on header bar
    transition: all 0.2s ease-out
    // Move to center of header to expose nav bar
    &.smaller
      top: 25%
      transform: scale(0.5) translate(-100%, -100%)
