// Find a way to use this with theme vars?
$hamburger-layer-color: #fff

$hamburger-hover-opacity: 1

// Don't compile unused icons
$hamburger-types: (elastic)

// Make hamburger use ems for responsivness
$hamburger-padding-x: 1em
$hamburger-padding-y: 1em
$hamburger-layer-width: 2.66em
$hamburger-layer-height: 0.266em
$hamburger-layer-spacing: 0.4em
$hamburger-layer-border-radius: 0.266em
